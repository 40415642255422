<template>
     <Tree  :options ="builder.treeObject"  ref="treeContainer" v-slot={item} class="p-2" @nodeClick = "item=>builder.nodeClick(item)">
         <!--<span :class="{'active':item.item.id === builder.active}" >{{item.item.name}} ({{item.item.count}})</span>-->
         <span :class="{'active':item === builder.treeObject.currentNode}" >{{item.item.caption}} ({{item.item.count}})</span>
    </Tree>
    <!--@nodeClick = "item=>groupByFolder.applyGroupByFilter(item)" @nodeExpand = "(node)=>groupByFolder.expand(node)" :options ="groupByFolder.current" v-slot={item}-->
</template>
<script setup>
   
    import Tree from 'o365.vue.components.Tree.vue';
    import {ref,watch} from 'vue';

    const props=defineProps(['builder']);
 
</script>
 

<style scoped>
   .active{
        background:rgba(var(--bs-primary-rgb), .35);
    }
</style>